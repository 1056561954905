import React from "react";

function TwoImageStrip() {
  return (
    <div className="mt-40">
      <div className="pic-1"></div>
      <div className="pic-2"></div>
    </div>
  );
}

export default TwoImageStrip;
